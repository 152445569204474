.ccc-screen {
  @apply bg-cover bg-fixed;

  h2 {
    @apply text-2xl text-black mb-8;
  }

  .container {
    @apply px-2;
  }

  & > header {
    @apply flex flex-col justify-between items-center w-full mb-4 pt-4 px-2 pb-2;
    border-bottom: 2px solid theme('colors.primary');

    h1 {
      @apply text-gray-800 text-3xl mb-0;
    }

    nav {
      @apply flex items-center;
    }

    nav a {
      @apply flex justify-center items-center h-10 w-10 rounded-full;
      color: #000 !important;

      span {
        @apply hidden pl-2 leading-none;
      }

      &:nth-child(n + 2) {
        @apply ml-2;
      }

      &:last-child {
        @apply ml-4;
      }

      &:hover, &.active {
        @apply bg-gray-800 no-underline;
        color: #FFF !important;
      }
    }
  }

  .card-footer .load-more {
    @apply w-full py-2 rounded-lg border-none font-semibold
      bg-gray-200 text-gray-800 transition transition-colors;

    &:hover {
      @apply bg-gray-700 text-white;
    }
  }

  @screen lg {
    .container {
      @apply px-4;
    }

    & > header {
      @apply flex-row;

      h1 {
        @apply text-4xl;
      }
  
      nav a {
        &:not(:last-child) {
          @apply w-auto px-4;
        }

        span {
          @apply block;
        }
      }
    }
  }
}