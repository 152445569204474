#alert-banner-container {
  transition: all 300ms ease-in-out;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 3rem;
  width: 100%;
  padding: 1rem 0.75rem;
  z-index: 2147483647;
  pointer-events: none;
}

#alert-banner-container.visible {
  pointer-events: auto;
}

#alert-banner {
  transition: all 300ms ease-in-out;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  background-color: #FAFAFC;
  border-radius: 0.5rem;
  border: 1px #FAFAFC solid;
  box-shadow: 0 4px 5px 2px rgba(0, 0, 0, 0.5);
  width: 100%;
  max-width: 960px;
  margin: 2rem auto 0;
  opacity: 0;
  pointer-events: none;
}

#alert-banner.error {
  color: #F00;
  background-color: #f7c4c8;
  border-color: #F90712;
}

#alert-banner.visible {
  opacity: 1;
  margin-top: 0;
  pointer-events: auto;
}

#alert-banner button {
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: none;
  color: #333;
  font-size: 1.5rem;
}

#alert-banner.error button {
  color: #F00;
}

@media screen and (min-width: 768px) {
  #alert-banner {
    padding: 0.5rem 1rem;
  }
}

@media screen and (min-width: 1200px) {
  #alert-banner-container {
    padding-left: calc(275px + 0.75rem);
  }

  .sidebar_minimize #alert-banner-container {
    padding-left: calc(75px + 0.75rem);
  }

  #alert-banner {
    padding: 0.75rem 1.25rem;
  }
}