.service-request__details {
  h4 {
    @apply text-xl text-gray-700;
  }

  dl {
    @apply flex flex-row flex-wrap;

    dd,
    dt {
      @apply w-1/2;
    }

    dt {
      @apply text-left;
    }

    dd {
      @apply text-right;
    }
  }

  dl + div {
    @apply flex flex-col items-stretch justify-start;

    span + span {
      @apply bg-gray-100 py-2 px-3 my-2 rounded text-left;
    }
  }
}
