.nav-search input[type='search']::-webkit-search-cancel-button {
  color: #fff;
  background-color: #fff;
}

#search-nav input:invalid + .input-group-append {
  visibility: hidden;
  pointer-events: none;
}

#site-search-results-container > div {
  @apply fixed inset-0 px-4 transition-opacity duration-200;
  background: rgba(0, 0, 0, 0.5);
  top: 3.75rem;
  z-index: 1001;

  &.is-hidden {
    pointer-events: none;
    opacity: 0;
  }

  > nav {
    @apply bg-white shadow-lg rounded-lg max-w-3xl mx-auto my-3 py-3;
    z-index: 1002;

    @screen lg {
      @apply py-5;
      margin-left: 280px;
    }
  }

  > nav aside {
    @apply flex justify-between text-xs px-3 pb-1;

    span:last-child {
      @apply text-blue-500 cursor-pointer;
    }

    @screen lg {
      @apply text-sm px-6;
    }
  }

  > nav a {
    @apply flex flex-col px-3 py-2;
    color: #333 !important;

    span:last-child {
      @apply text-sm;
      color: #555 !important;
    }

    &:hover,
    &:focus,
    &:active {
      @apply bg-blue-700;
    }

    &:hover span,
    &:focus span,
    &:active span {
      color: #fff !important;
      text-decoration: none !important;
    }

    @screen lg {
      @apply flex-row items-end px-6;

      span {
        @apply truncate mr-4;
      }
    }
  }
}
