.agency-map__location-info h6 {
  text-transform: capitalize;
}

.agency-map__location-info dl {
  display: flex;
  flex-flow: row wrap;
}

.agency-map__location-info dt,
.agency-map__location-info dd {
  width: 50%;
}

.agency-map__location-info dt:after {
  content: ':'
}

.agency-map__location-info dd {
  text-align: right;
}

#map-page .gm-ui-hover-effect.gm-ui-hover-effect {
  top: 0 !important;
  right: 0 !important;
}