.site-nav {
  @apply fixed top-0 bottom-0 left-0 bg-white text-gray-600 font-thin;
  z-index: 1000;
  width: 275px;
  box-shadow: 4px 4px 10px rgb(69 65 78 / 6%);
  transition: all 300ms;

  @media (min-width: 992px) {
    @apply mt-16;

    .sidebar_minimize & {
      width: 75px;

      svg {
        @apply text-center mx-auto;
      }

      &__subheading:first-child,
      &__links > a > span,
      &__current-user,
      &__group span,
      &__group a {
        @apply hidden;
      }

      &__subheading {
        @apply relative;
        text-indent: -9999px;

        &::after {
          @apply absolute text-gray-500 text-4xl text-right w-12;
          content: '\2026';
          top: -1.5rem;
          right: 1.5rem;
        }
      }
    }
  }

  @media (max-width: 991px) {
    width: 250px;
    transition: all 500ms;

    html:not(.nav_open) & {
      transform: translate3d(-250px, 0, 0);
    }

    html.nav_open & {
      transform: translate3d(0, 0, 0);
    }
  }

  &__wrapper {
    @apply relative max-h-screen min-h-full overflow-y-auto w-full pb-24;
    z-index: 4;
    transition: all 0.3s;
  }

  &__current-user {
    @apply flex flex-col mx-4 mt-4 mb-2 pb-2 font-normal border-0 border-b border-solid border-gray-300;
  }

  &__links > * {
    @apply mb-1;
  }

  &__subheading {
    @apply font-bold text-sm text-gray-700 px-4 mt-6 mb-3 tracking-wide uppercase;
  }

  &__group__toggle,
  &__links > a {
    @apply flex items-center px-4 py-3 cursor-pointer;
    color: theme('colors.gray.600') !important;

    svg + span {
      @apply flex-grow pl-4 pr-2;
    }

    &:hover {
      @apply bg-gray-200 no-underline;
      color: theme('colors.gray.800') !important;
    }

    &[data-active] {
      @apply bg-primary font-semibold;
      color: theme('colors.white') !important;
    }

    .caret {
      transition-duration: 250ms;
    }
  }

  &__group {
    transition: all 250ms;

    &__links {
      @apply flex flex-col h-0 invisible overflow-hidden;
      transition: all 250ms;
    }

    &__links > span {
      @apply pl-4 pr-2 my-1 italic text-gray-800;
    }

    &__links > a {
      @apply pl-6 pr-2 py-2 my-1 no-underline;
      color: theme('colors.gray.600') !important;

      @screen xl {
        @apply py-1;
      }

      &:hover {
        @apply bg-gray-300 no-underline;
        color: theme('colors.black') !important;
      }

      &[data-active] {
        @apply bg-gray-300 font-semibold;
        color: theme('colors.black') !important;
      }
    }

    &[data-open] {
      @apply text-red-800;
    }

    &[data-open] &__links {
      @apply h-auto visible mb-3;
    }

    &[data-open] .caret {
      @apply transform rotate-180;
    }
  }
}
