#delivery-code-scanner {
  @apply relative w-full my-0 mx-auto text-center;

  #map-container {
    @apply relative w-full mb-6;
    min-height: calc(100vh - 8rem);
  }

  .logout-button {
    position: absolute;
    top: 0.5rem;
    right: 1rem;
    z-index: 1;
    transform: scale(1.5);
    transform-origin: top right;
    color: #000;
    background: transparent;
    border: none;
  }

  @screen 2xl {
    #map-container {
      min-height: 600px;
    }
  }
}

.main-header ~ .main-panel #delivery-code-scanner {
  @apply mt-10;

  .btn.btn-danger,
  .logout-button {
    display: none;
  }
}
