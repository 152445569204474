#vehicle-inspection-form {
  &.top-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 1rem 1.5rem 0;
  }

  &.top-buttons a {
    margin-left: 4rem;
    transform: scale(1.25);
    background: transparent;
    border: none;
  }

  .login-aside:first-of-type {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 420px;
  }

  .logout-button {
    transform: scale(1.25);
    background: transparent;
    border: none;
  }

  .login-aside + .login-aside {
    margin-left: 420px !important;
    width: calc(100vw - 420px);
    height: unset;

    .container {
      width: 100%;
    }

    .content {
      margin: 0 auto;
    }
  }

  .logout-button + .login-aside p + a {
    position: absolute;
    bottom: 3rem;
    font-weight: 600;
    background: #fff;
    color: #00aaef;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    text-decoration: none !important;
  }

  .form-group {
    position: relative;
  }

  .plate-remove {
    position: absolute;
    right: -2rem;
    bottom: 1rem;
    transform: scale(1.25);
  }

  .plate-remove svg path {
    fill: #a20d04;
  }

  table {
    width: 100%;
  }

  table .text-center strong {
    display: block;
    padding: 0.5rem 0 0.5rem;
  }

  thead th {
    text-align: center;
  }

  tbody td {
    border-top: 1px solid #ccc;
    padding: 0.75rem 0.25rem 0.5rem;
  }

  tbody tr:nth-child(even) td {
    background: #dfdfdf;
  }

  tbody tr:nth-child(odd) {
    background: #fff !important;
  }

  .vehicle-inspection-confirm-plates h3 {
    margin-bottom: 1rem;
  }

  .vehicle-inspection-confirm-plates h3 ~ div {
    display: flex;
    justify-content: space-between;
    color: #555;
    margin: 0.5rem 1.25rem;
  }

  @media screen and (max-width: 1024px) {
    #vehicle-inspection-form.checklist .logout-button + .login-aside {
      width: 0;
      padding: 0;
    }

    #vehicle-inspection-form.checklist .login-aside + .login-aside {
      margin-left: 0 !important;
      width: 100vw;
    }

    .login-form .progress-card {
      margin-top: 1.5rem;
    }

    #vehicle-inspection-form.top-buttons a {
      margin-left: 4rem;
      transform: scale(1.5);
      background: transparent;
      border: none;
    }

    .logout-button {
      transform: scale(1.5);
      background: transparent;
      border: none;
    }
  }
}

#farm-inspection-form {
  &.top-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 1rem 1.5rem 0;
  }

  &.top-buttons a {
    margin-left: 4rem;
    transform: scale(1.25);
    background: transparent;
    border: none;
  }

  .login-aside:first-of-type {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 420px;

    nav {
      @apply absolute bottom-0 inset-x-0 px-4 pb-4
        flex justify-between w-full;
    }

    nav :is(a ,button) {
      @apply flex items-center text-lg bg-transparent border-none;
      color: #FFF !important;
    }

    nav svg {
      @apply text-base mr-2;
    }
  }

  .login-aside + .login-aside {
    margin-left: 420px !important;
    width: calc(100vw - 420px);
    height: unset;

    .container {
      width: 100%;
    }

    .content {
      margin: 0 auto;
    }
  }

  .logout-button + .login-aside p + a {
    position: absolute;
    bottom: 3rem;
    font-weight: 600;
    background: #fff;
    color: #00aaef;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    text-decoration: none !important;
  }

  .form-group {
    position: relative;
  }

  .plate-remove {
    position: absolute;
    right: -2rem;
    bottom: 1rem;
    transform: scale(1.25);
  }

  .plate-remove svg path {
    fill: #a20d04;
  }

  table {
    width: 100%;
  }

  table .text-center strong {
    display: block;
    padding: 0.5rem 0 0.5rem;
  }

  thead th {
    text-align: center;
  }

  tbody td {
    border-top: 1px solid #ccc;
    padding: 0.75rem 0.25rem 0.5rem;
  }

  tbody tr:nth-child(even) td {
    background: #dfdfdf;
  }

  tbody tr:nth-child(odd) {
    background: #fff !important;
  }

  /* Farm Selection */
  .farm-selection {
    @apply px-4 pb-1 pt-3 mb-2 cursor-pointer;
    border-bottom: 1px solid #BABAAA;

    &:hover {
      @apply bg-gray-100;
    }

    h5, h6 {
      @apply mb-0;
    }
  }

  /* Form CSS Overrides */
  .form-group.form-group {
    @apply flex flex-col items-stretch;

    label {
      @apply w-full max-w-full;
      text-align: left !important;
    }

    label[for] + div {
      @apply max-w-full;
    }

    &.row > h5 {
      margin-left: 0 !important;
      border-bottom: 1px solid #369;
    }
  }

  .form-check.form-check {
    @apply ml-0;
  }

  [data-field][data-array] {
    & > h5 {
      @apply text-lg;
    }

    [data-row] > .flex {
      padding: 0 !important;
    }
  }
}

.user-upload-blowup {
  width: 1280px;
  max-width: 100%;
  padding: 1.5rem;
}
