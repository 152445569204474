/*     Alert    */

.alert {
  border: 0px;
  position: relative;
  padding: .95rem 1.25rem;
  border-radius: 1px;
  color: inherit;
  background-color: #FFF !important;
  -webkit-box-shadow: 1px 1px 14px 0px rgba(18, 38, 63, 0.26) !important;
  -moz-box-shadow: 1px 1px 14px 0px rgba(18, 38, 63, 0.26) !important;
  box-shadow: 1px 1px 14px 0px rgba(168, 185, 207, 0.26) !important;
}

.alert [data-notify="icon"] {
  line-height: 35px !important;
  font-size: 22px !important;
  display: block !important;
  left: 15px !important;
  position: absolute !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
  width: 35px !important;
  height: 35px !important;
  border-radius: 30px !important;
  text-align: center !important;
  color: #fff;
}

.alert [data-notify="message"] {
  color: #555 !important;
}

.alert .close {
  background: rgba(255, 255, 255, 0.8) !important;
  width: 25px !important;
  height: 25px !important;
  line-height: 25px !important;
  top: 12px !important;
  border-radius: 50% !important;
}


/*    Alert States    */

.alert-primary {
  border-left: 0.5rem solid #1572E8 !important;
}

.alert-primary [data-notify="icon"] {
  color: #1572E8;
}

.alert-info {
  border-left: 0.5rem solid #48ABF7 !important;
}

.alert-info [data-notify="icon"] {
  color: #48ABF7;
}

.alert-success {
  border-left: 0.5rem solid #31CE36 !important;
}

.alert-success [data-notify="icon"] {
  color: #31CE36;
}

.alert-warning {
  border-left: 0.5rem solid #FFAD46 !important;
  ;
}

.alert-warning [data-notify="icon"] {
  color: #FFAD46;
}

.alert-danger {
  border-left: 0.5rem solid #F25961 !important;
}

.alert-danger [data-notify="icon"] {
  color: #F25961;
}