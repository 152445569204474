.appointments-list {
  position: relative;
  
  .appointments-list-header {
    position: sticky;
    top: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .card.appointment-card {
    border: 2px solid transparent !important;
  }
  
  .card.appointment-card .card-body > div {
    display: flex;
    justify-content: space-between;
  }
  
  .card.appointment-card.appointment-card-active {
    border: 2px solid #00AAEF !important;
  }
}
