/* PRIMARY BLUE: #00AEEF */

#otp-screen {
  width: 100vw;
  height: 100vh;
  background: url('../images/main-bg.jpg') no-repeat;
  background-size: cover;
}

.sidebar_minimize .nav-toggle .btn-toggle {
  padding: 0 !important;
}

.main-header .logo-header.logo-header {
  /* height: 64px; */
}

.main-panel > .container > .content {
  min-height: calc(100vh - 120px);
  background-size: cover;
  margin-top: 60px;
}

div#basic-datatables_info {
  width: 0;
}

.react-datepicker-wrapper {
  width: 100%;
}

.agree-label {
  font-size: 11px;
}

.term_c_main {
  height: 700px;
  overflow-x: auto;
}

.privacy_main {
  height: 700px;
  overflow-x: auto;
}

.payment-result {
  font-size: 1rem;
  text-align: center;
}

.smaller-tr {
  color: black;
  font-size: 0.625rem;
  text-align: left;
}

.smaller-otp {
  color: black;
  font-size: 0.875rem;
  text-align: left;
}

.modTitle-h {
  font-weight: 600;
  font-size: 1.125rem;
  text-decoration: underline;
}

.welcome-text {
  font-size: 1.125rem;
  margin-bottom: 25px;
  margin-top: 25px !important;
}

.button-up-in {
  margin-top: 30px;
}

.button-up-in button {
  width: 90px;
}

.button-up-in button:nth-child(2) {
  margin-left: 10px;
}

.termndpolicy {
  text-align: center;
}

.rtd-main {
  width: 400px;
}

.rtd-title {
  font-weight: 600;
  padding-right: 5px;
}

.rtd-value {
  margin-left: 50px;
}

.payment-result .pr-title {
  font-weight: 600;
}

.payment-result .pr-value {
  padding-left: 5px;
}

a svg {
  font-size: 1.125rem !important;
  width: 25px !important;
}

.custom-ui {
  text-align: center;
  width: 500px;
  padding: 40px;
  background: white;
  border: 1px solid #00aeef;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
  color: #fff;
}

.custom-ui h3 {
  color: #4d88c7;
}

.custom-ui > button {
  width: 160px;
  /* padding: 10px; */
  /* background: #2c89e6 !important; */
  /* border: 1px solid #00AEEF; */
  margin: 0.625rem 1rem;
  cursor: pointer;
  background: none;
}

.dcxtLi,
.kJnpoy img {
  width: 23px !important;
  height: 25px !important;
}

.static-card {
  padding-right: 20px;
}

.static-card img {
  width: 25px;
  margin-left: 25px;
}

.static-card img:nth-child(2) {
  margin-left: 5px;
}

.navbar-nav.social-icons .nav-link {
  padding: 0.3125rem 0.625rem !important;
  margin: 0 0.25rem;
}

.navbar-nav.social-icons .nav-link svg {
  font-size: 1.5rem !important;
}

@media (min-width: 991px) {
  .sidebar.sidebar,
  .logo-header.logo-header {
    width: 275px;
  }

  .main-panel.main-panel {
    width: calc(100% - 275px);
  }
}

.sidebar .nav > .nav-item a svg,
.sidebar[data-background-color='white'] .nav > .nav-item a svg {
  color: #8d9498;
  margin-right: 15px;
  width: 25px;
  text-align: center;
  vertical-align: middle;
  float: left;
  font-size: 1.125rem;
  line-height: 30px;
}

.sidebar .nav-item.active svg {
  color: #fff !important;
}

.active img {
  fill: white;
}

.custom-radio {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-weight: normal !important;
}

/* Hide the browser's default radio button */
.custom-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.custom-radio .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #ccc;
}

/* On mouse-over, add a grey background color */
.custom-radio:hover input ~ .checkmark {
  background-color: #fff;
  border-color: #00aeef;
}

/* When the radio button is checked, add a blue background */
.custom-radio input:checked ~ .checkmark {
  background-color: #00aeef;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.custom-radio .checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.custom-radio input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.custom-radio .checkmark:after {
  top: 5px;
  left: 5px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

.radio-bx input {
  padding: 0;
  margin: 0;
  width: 15px;
  margin-right: 5px;
}

.radio-bx {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 8px;
}

.main-panel > .container {
  padding: 0px !important;
  min-height: calc(100% - 68px);
  margin-top: 0;
  overflow: hidden;
  width: 100%;
  max-width: unset;
}

.main-panel .page-inner.page-inner {
  @apply pt-2 mt-0;

  @screen md {
    @apply pt-20;
  }
}

.main-panel .page-inner.no-v-padding {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.main-panel .page-inner.not-found {
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.wrapper-signup .react-datepicker-wrapper {
  width: 100%;
}

.btn-secondary {
  background: #00aeef !important;
  border-color: #00aeef !important;
}

.btn-secondary:disabled,
.btn-secondary:focus,
.btn-secondary:hover {
  background: #2267ac !important;
  border-color: #2267ac !important;
}

.nav-pills.nav-pills-icons svg {
  display: block;
  margin: 1rem auto;
  font-size: 2rem !important;
  width: unset !important;
  line-height: 3rem;
}

.nav-pills.nav-primary .nav-link.active {
  background: #00aeef !important;
  border: 1px solid#00AEEF !important;
}

.main-panel .page-header {
  margin-top: 63px;
  margin-bottom: 0px !important;
}

.ct-0 {
  transform: rotate(0deg) !important;
}

.ct-180 {
  transform: rotate(180deg);
}

.sidebar_minimize h5 {
  font-size: 0.75rem !important;
}

.btn-new-color {
  background: #2267ac !important;
  border-color: #2267ac !important;
  color: white !important;
}

.form-group-default {
  overflow: visible;
}

.modal-m.fade .modal-m-dialog {
  transform: translate(0px, -25%);
  transition: transform 0.3s ease-out 0s;
}

.modal-m.fade.in .modal-dialog {
  transform: translate(0px, 0px);
}

.alert-box {
  position: absolute;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.login-card {
  top: 20%;
}

.justify-content-center {
  margin: 0 !important;
}

.flyover {
  left: 150%;
  overflow: hidden;
  position: fixed;
  width: 30%;
  opacity: 0.95;
  z-index: 1050;
  transition: left 0.6s ease-out 0s;
}

.jumbotron {
  padding: 1rem 2rem !important;
}

.flyover-centered {
  top: 10%;
  transform: translate(-50%, -50%);
}

.flyover.in {
  left: 83%;
}

.input_error {
  border-color: #f25961 !important;
  color: #f25961 !important;
}

.input_error::placeholder {
  color: #f25961 !important;
}

.label_error {
  color: #f25961 !important;
}

.h4,
h4 {
  font-size: 1.5rem;
}

.board {
  overflow-x: auto;
  overflow-y: hidden;
}

.custom-template {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  right: -325px;
  width: 325px;
  height: max-content;
  display: block;
  z-index: 1;
  background: #ffffff;
  transition: all 0.3s;
  z-index: 1003;
  box-shadow: -1px 1px 20px rgba(69, 65, 78, 0.15);
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  transition: all 0.5s;
}

.custom-template.open {
  right: 0px;
}

.custom-template .custom-toggle {
  position: absolute;
  width: 45px;
  height: 45px;
  background: rgb(88, 103, 221);
  top: 50%;
  left: -45px;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-right: 1px solid #177dff;
  cursor: pointer;
  color: #ffffff;
  box-shadow: -5px 5px 20px rgba(69, 65, 78, 0.21);
}

.custom-template .custom-toggle i {
  font-size: 1.25rem;
  animation: 1.3s spin linear infinite;
}

.custom-template .title {
  padding: 15px;
  text-align: left;
  font-size: 1.125rem;
  font-weight: 600;
  color: #ffffff;
  border-top-left-radius: 5px;
  border-bottom: 1px solid #ebedf2;
  background: #5867dd;
}

.custom-template .custom-content {
  padding: 20px 15px;
  max-height: calc(100vh - 90px);
  overflow: auto;
}

.custom-template .switcher {
  padding: 5px 0;
}

.custom-template .switch-block h4 {
  font-size: 0.875rem;
  font-weight: 600;
  color: #444;
  line-height: 1.3;
  margin-bottom: 0;
  text-transform: uppercase;
}

.custom-template .btnSwitch {
  margin-top: 20px;
  margin-bottom: 25px;
}

.custom-template .btnSwitch button {
  border: 0px;
  height: 20px;
  width: 20px;
  outline: 0;
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  padding: 0;
  border-radius: 50%;
  border: 2px solid #eee;
  position: relative;
  transition: all 0.2s;
}

.custom-template .btnSwitch button:hover {
  border-color: #0bf;
}

.custom-template .btnSwitch button.selected {
  border-color: #0bf;
}

.custom-template .img-pick {
  padding: 4px;
  min-height: 100px;
  border-radius: 5px;
  cursor: pointer;
}

.custom-template .img-pick img {
  height: 100%;
  height: 100px;
  width: 100%;
  border-radius: 5px;
  border: 2px solid transparent;
}

.custom-template .img-pick:hover img,
.custom-template .img-pick.active img {
  border-color: #177dff;
}

.demo .btn,
.demo .progress {
  margin-bottom: 15px !important;
}

.demo .form-check-label,
.demo .form-radio-label {
  margin-right: 15px;
}

.demo .toggle,
.demo .btn-group {
  margin-right: 15px;
}

.demo #slider {
  margin-bottom: 15px;
}

.table-typo tbody > tr > td {
  border-color: #fafafa;
}

.table-typo tbody > tr > td:first-child {
  min-width: 200px;
  vertical-align: bottom;
}

.table-typo tbody > tr > td:first-child p {
  font-size: 0.875rem;
  color: #333;
}

.demo-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  padding: 10px;
  transition: all 0.2s;
}

.demo-icon:hover {
  background-color: #f4f5f8;
  border-radius: 3px;
}

.demo-icon .icon-preview {
  font-size: 1.8rem;
  margin-right: 10px;
  line-height: 1;
  color: #333439;
}

body[data-background-color='dark'] .demo-icon .icon-preview {
  color: #969696;
}

.demo-icon .icon-class {
  font-weight: 300;
  font-size: 0.875rem;
  color: #777;
}

body[data-background-color='dark'] .demo-icon .icon-class {
  color: #a9a8a8;
}

.form-show-notify .form-control {
  margin-bottom: 15px;
}

.form-show-notify label {
  padding-top: 0.65rem;
}

.map-demo {
  height: 300px;
}

#instructions li {
  padding: 5px 0;
}

.row-demo-grid {
  margin-bottom: 15px;
}

.row-demo-grid [class^='col'] {
  text-align: center;
}

.row-demo-grid [class^='col'] .card-body {
  background: #ddd;
}

.btnSwitch button[data-color='white'] {
  background-color: #fff;
}

.btnSwitch button[data-color='grey'] {
  background-color: #f1f1f1;
}

.btnSwitch button[data-color='black'] {
  background-color: #191919;
}

.btnSwitch button[data-color='dark'] {
  background-color: #1a2035;
}

.btnSwitch button[data-color='blue'] {
  background-color: #fdb90b;
}

.btnSwitch button[data-color='purple'] {
  background-color: #6861ce;
}

.btnSwitch button[data-color='light-blue'] {
  background-color: #48abf7;
}

.btnSwitch button[data-color='green'] {
  background-color: #31ce36;
}

.btnSwitch button[data-color='orange'] {
  background-color: #ffad46;
}

.btnSwitch button[data-color='red'] {
  background-color: #f25961;
}

.btnSwitch button[data-color='dark2'] {
  background-color: #1f283e;
}

.btnSwitch button[data-color='blue2'] {
  background-color: #ffb83cd4;
}

.btnSwitch button[data-color='purple2'] {
  background-color: #5c55bf;
}

.btnSwitch button[data-color='light-blue2'] {
  background-color: #3697e1;
}

.btnSwitch button[data-color='green2'] {
  background-color: #2bb930;
}

.btnSwitch button[data-color='orange2'] {
  background-color: #ff9e27;
}

.btnSwitch button[data-color='red2'] {
  background-color: #ea4d56;
}

.btnSwitch button[data-color='bg1'] {
  background-color: #fafafa;
}

.btnSwitch button[data-color='bg2'] {
  background-color: #fff;
}

.btnSwitch button[data-color='bg3'] {
  background-color: #f1f1f1;
}

.btn {
  padding: 0.65rem 1.4rem !important;
  opacity: 1 !important;
  border-radius: 3px !important;
}

.btn:focus,
.btn:hover {
  opacity: 0.9 !important;
  transition: all 0.3s !important;
}

.card-profile .user-profile .name {
  text-transform: capitalize;
}

.card,
.card-light {
  box-shadow: 2px 6px 15px 0 rgba(69, 65, 78, 0.1) !important;
  border: none !important;
}

.row-nav-line {
  margin-left: -20px !important;
  margin-right: -20px !important;
}

.nav-search .btn-search {
  background: 0 0;
  padding: 0.375rem 1rem !important;
}

.nav-search .form-control {
  border: 0;
  background: 0 0 !important;
  font-size: 0.875rem;
  padding: 0.75em 0.2em !important;
  min-width: 200px;
  max-width: 100%;
}

.form-control:focus {
  background: transparent !important;
}

.react-dropdown-select-dropdown {
  width: 100% !important;
}

.css-3rj0br-ReactDropdownSelect {
  padding: 0.6rem 1rem !important;
}

.CertificateTable a {
  color: #369 !important;
}

.CertificateTable a:hover {
  color: #2a5179 !important;
}

.custom-btn {
  background: #00aeef !important;
  border: #00aeef !important;
  color: #fff !important;
}

.custom-btn:hover,
.custom-btn:active {
  background: #4d88c7 !important;
  border: #4d88c7 !important;
  color: #fff !important;
}

.custom-btn:disabled {
  background: #ccc !important;
  border: #bbb !important;
  color: #555 !important;
}

.btn-border.btn-orange {
  color: #fac71f !important;
  border: 1px solid #fac71f !important;
}

@media screen and (max-width: 991px) {
  .main-header {
    background-color: transparent !important;
    box-shadow: none !important;
    height: 63px;
  }

  .logo-header .logo .navbar-brand {
    width: auto !important;
  }

  .topbar_open .navbar-header {
    transform: translate3d(0, 1px, 0) !important;
  }

  .dataTables_filter label {
    float: left !important;
  }
}

@media screen and (max-width: 550px) {
  .table-typo tr td {
    display: flex;
    align-items: center;
    word-break: break-word;
  }

  .table-typo tr td:first-child p {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 576px) {
  .custom-template .custom-content {
    overflow: auto;
  }

  .form-show-notify > .text-right,
  .form-show-validation > .text-right {
    text-align: left !important;
  }

  #vehicle-inspection-form .logout-button {
    top: 1.5rem !important;
    color: #fff;
  }
}

@media screen and (max-width: 400px) {
  .custom-template {
    width: 85% !important;
    right: -85%;
  }
}

/* Blockcerts Forms */

.certs-form h4 {
  margin-bottom: 2rem;
}

.certs-form .page-inner,
.certs-success-card .page-inner {
  margin-top: 4rem;
}

.certs-form .file-input,
.certs-form .batch-description {
  padding: 1rem;
}

.certs-form select.form-control {
  height: calc(1.5em + 0.75rem + 2px) !important;
}

.certs-form #recipients-csv-form [type='file'] {
  position: absolute;
  visibility: hidden;
}

.certs-form #recipients-csv-form [type='file'] + div {
  position: absolute;
  top: 3.75rem;
  left: 1rem;
}

.certs-form #recipients-csv-form > label {
  width: 100%;
  padding: 0.5rem;
  text-align: center;
  font-weight: bold;
  border-radius: 0.25rem;
  color: #212121;
  border: 2px dotted #454545;
  background-color: #dfdfdf;
}

.certs-form #recipients-csv-form [type='reset'] {
  position: relative;
  top: -0.25rem;
  appearance: none;
  background: none;
  padding: 0;
  margin: 0;
  border: none;
  float: right;
  color: #369;
}

.certs-form #recipients-csv-form [type='submit'] {
  display: block;
  margin-top: 1rem;
  width: 100%;
}

.certs-form .sidenote {
  padding: 0 1.25rem 0.5rem;
}

.certs-form .recipient-cards .col {
  flex: 0 0 100%;
  max-width: 400px;
}

.certs-form .recipient-cards .add.card {
  background-color: rgba(0, 0, 0, 0);
  box-shadow: none;
}

#issue-certs-form .recipient-cards .add.card {
  min-height: 200px;
  height: calc(100% - 30px);
}

#revoke-certs-form .recipient-cards .add.card {
  min-height: 200px;
  height: calc(100% - 30px);
}

.certs-form .recipient-cards .add.card button {
  height: 100%;
  border: 3px dashed #757575;
  border-radius: 0.25rem;
  background-color: transparent;
}

.certs-form .card-header {
  display: flex;
  justify-content: space-between;
}

.certs-form .card-header > h5 {
  margin-bottom: 0;
}

.recipient-cards .form-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
}

.recipient-cards .form-group.checkbox {
  justify-content: initial;
}

.recipient-cards .form-group.checkbox > label {
  /* display: inline-block; */
  margin-right: 5rem;
}

.batch-details .form-group,
.recipient-cards .form-group label {
  margin-bottom: 0;
}

.recipient-cards .form-control {
  width: 200px;
}

#issue-certs-success .card-body > div {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

#issue-certs-success .card-body > div > div {
  text-align: center;
  margin: 1rem 1rem 0;
}

#issue-certs-success .card-body > div small + a {
  display: block;
}

.certs-success-card tr.failure {
  color: red;
}

.certs-success-card tr.failure:hover {
  background-color: rgba(224, 0, 16, 0.1);
  color: red !important;
}

.certs-success-card tr.success {
  color: green;
}

.certs-success-card tr.success:hover {
  background-color: rgba(0, 255, 0, 0.25);
  color: green !important;
}

.certs-success-card td {
  padding: 0.5rem 1rem 0.5rem 0;
}

/* Vehicle Inspection Form */
.carousel .thumbs {
  padding: 0 !important;
  padding-inline-start: 0 !important;
}
