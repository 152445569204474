:root {
  --loader-container-bg: rgba(0, 0, 0, 0.6);
  --loader-container-fg: #dadada;
}

.loader-container {
  @apply fixed flex flex-col inset-0 w-full h-full justify-center items-center;
  z-index: 1000;
  background: var(--loader-container-bg);

  > div {
    color: var(--loader-container-fg);
    font-size: 4rem;
  }
}
