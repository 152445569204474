.form-wizard__first-screen {
  @apply pt-2;
  background-image: url('../images/backdrops/a.jpg');

  @screen lg {
    @apply pt-8;
  }

  article {
    @apply text-center my-0 mx-auto max-w-2xl;
  }

  article > .flex a {
    @apply rounded-lg flex items-center bg-primary px-4 py-3 mb-8;
    color: white !important;
    text-decoration: none !important;

    svg {
      @apply mr-2;
    }

    span {
      @apply font-bold;
    }

    @screen sm {
      @apply flex-col justify-between w-48 mx-4 py-3 px-5;

      svg {
        @apply mr-0 mb-4;
        transform: scale(1.8) translateY(0.3125rem);
        transform-origin: top;
      }

      span {
        @apply mt-6;
      }
    }
  }
}

.minister-applications-table {
  .actions span {
    @apply cursor-pointer font-semibold text-blue-500;

    &[data-disabled] {
      @apply text-gray-500 cursor-not-allowed;
    }
  }
}

.application-details + .content,
.application-details ~ .content.minister-applications-table {
  @apply hidden;
}

/* React Datepicker: Put icons to right end of input field */
.input-group > .react-datepicker-wrapper input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .react-datepicker-wrapper {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}

.card-img-top.text-center > img {
  width: 100%;
}

.wizard-container {
  /* FormWizard: Automatically set width to wizard step pills */
  .wizard-menu li {
    @apply pointer-events-none flex-auto;
  }

  .wizard-menu li:first-child {
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
  }

  .wizard-menu li:last-child {
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
  }

  @screen xs {
    .wizard-body.wizard-body {
      @apply px-2;
    }

    &.wizard-round.wizard-round {
      @apply -mt-3;
    }

    .wizard-body .tab-content.tab-content {
      @apply px-0;
    }

    .wizard-action.wizard-action {
      @apply px-5;
    }

    .cc-input > div {
      @apply w-full;
    }

    #cardNumber {
      flex-grow: 1;
    }
  }
}

.form-wizard__form {
  /* FormWizard.Form: Use flex to set label & field sizes */
  label[for] + div {
    width: 100%;
    max-width: 420px;
  }

  label[for] ~ span[data-tip] {
    @apply ml-2 mt-1;
  }

  .acceptance-text {
    @apply flex flex-row items-center;
    max-width: 710px;
  }

  label[for] {
    @apply mb-0 whitespace-normal;
  }

  label[for].large-label + div {
    max-width: 325px;
  }

  label[for].wide-input + div {
    max-width: 600px;

    .form-wizard-checklist > div {
      @apply flex flex-row items-center mb-3;
    }
  }

  label[for='island'] + div .react-dropdown-select-item,
  label[for='island'] + div .react-dropdown-select-content {
    text-transform: capitalize;
  }

  .form-check.form-show-validation {
    @apply mx-0 py-4 px-0;

    @screen md {
      @apply pl-5 py-2;
      margin-left: 20%;
    }

    .form-check-sign {
      font-weight: 600 !important;

      &::before,
      &:hover::before,
      &:focus::before {
        @apply border-2 border-gray-700;
      }
    }
  }

  .form-wizard-checklist .form-check {
    @apply p-0;
  }

  .form-check [type='checkbox'] + .form-check-sign,
  .form-check [type='checkbox']:checked + .form-check-sign,
  .form-check [type='checkbox']:not(:checked) + .form-check-sign {
    @apply whitespace-normal font-normal;
  }

  .form-check [type='checkbox'] + .form-check-sign:after,
  .form-check [type='checkbox']:checked + .form-check-sign:after,
  .form-check [type='checkbox']:not(:checked) + .form-check-sign:after {
    content: '\2713';
    font-weight: 600;
    font-family: inherit;
  }

  [data-table] tr {
    @apply relative;
  }

  [data-array] [data-row] {
    @apply relative border-dotted border-gray-300 pt-2;
    border-width: 2px 0 2px 0;

    &:last-of-type {
      @apply -mt-px mb-4;
    }
  }

  [data-array] [data-close],
  [data-field] [data-close] {
    @apply absolute cursor-pointer bg-transparent border-none;
    top: 1rem;
    right: 0.5rem;
    color: #f25961;
  }

  [data-array] [data-close] {
    top: 0.5rem;
    right: 0;
  }

  [data-field] aside button {
    @apply border-none bg-transparent;
    color: #00aeef;
  }

  [data-array] aside button {
    @apply border border-solid py-1 px-4 rounded-lg shadow
      bg-gray-800 text-white;
  }

  .stacked-field.stacked-field {
    @apply flex-col items-stretch w-full max-w-2xl;

    @screen md {
      margin-left: 17.5%;
    }
  }

  .stacked-field label[for],
  .stacked-field label[for] + * {
    width: 100% !important;
    max-width: unset;
    text-align: left !important;

    .input-group {
      @apply w-full;
    }
  }
}

#form-wizard__proxy-select {
  .separator-solid + span {
    @apply block px-8 bg-white;
    position: relative;
    top: -1.25rem;
  }

  .sub-account-card {
    @apply flex flex-col items-center rounded border-2 border-solid border-transparent p-4 mx-4 mb-4 cursor-pointer;

    &.active {
      @apply border-primary;
    }

    &:hover {
      @apply bg-gray-200;
    }

    div {
      @apply flex justify-center items-center rounded-full w-16 h-16 mb-4 text-2xl text-white bg-gray-700;
    }

    p {
      @apply mb-2;
    }
  }

  form {
    @apply max-w-full;
    width: 30rem;
  }
}

.form-wizard__dependencies {
  [data-decision] {
    display: flex;
    justify-content: space-between;
    max-width: 360px;
    margin: 0 auto 0.25rem;
  }

  [data-decision] strong:last-child {
    text-transform: capitalize;
  }

  [data-decision='pending'] strong:last-child {
    color: #f0940a;
  }

  [data-decision='approved'] strong:last-child {
    color: #0b8a0d;
  }

  [data-decision='none'] strong:last-child {
    color: #756a6a;
  }
}

.form-wizard__mode-switch,
.form-wizard__proxy-reqs {
  h5 {
    border-bottom: 1px solid #dbdbd5;
    margin-bottom: 0.5rem;
  }

  span {
    position: relative;
    display: block;
    cursor: pointer;
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    margin-bottom: 0.25rem;
  }

  span::after {
    position: absolute;
    content: '\022EF';
    right: 1rem;
    top: 0.25rem;
    color: #00aeef;
  }

  span:hover {
    background-color: #ebebeb;
  }

  span.active {
    font-weight: 600;
    background-color: #00aeef;
    color: white;
  }

  span.active::after {
    color: #fff;
  }
}

.form-records-table {
  &.clickable-rows [role='row'] {
    cursor: pointer;
  }

  &.clickable-rows [role='row']:hover {
    background: #ced1e9;
  }

  [role='heading'] {
    display: none;
  }

  [role='columnheader'],
  [role='gridcell'] {
    font-size: 1rem;
  }

  [role='columnheader'] {
    font-weight: 600;
  }

  [data-action] {
    color: #369 !important;
    cursor: pointer;
  }

  [data-currency]:before {
    content: '$';
  }
}

.form-records-table-search {
  @apply flex justify-end;

  &.searchbar {
    @screen 3xl {
      @apply absolute;
      top: 0.75rem;
      right: 1.25rem;
    }
  }

  & > button {
    @apply bg-transparent text-gray-800 border-b-2;
    border: 0;
    border-bottom: 2px solid transparent;
    margin-bottom: -2px;

    svg {
      @apply mr-2;
    }

    &:hover {
      border-bottom-color: #000;
    }
  }

  #record-actions-search-options {
    @apply bg-transparent border-b-2;
    color: theme('colors.gray.800') !important;
    border: 0;
    border-bottom: 2px solid transparent;
    margin-bottom: -2px;

    &:hover {
      border-bottom-color: #000;
    }
  }
}

.form-wizard-picker-options {
  background: #dee2e6;
  max-height: 65vh;
  overflow-y: auto;

  div {
    font-size: 1rem;
    padding: 0.5rem 1rem;
    margin: 0.5rem 0;
    background: #fff;
    border-radius: 0.5rem;
    cursor: pointer;
  }

  div:hover {
    background: rgba(0, 174, 234, 0.25);
  }
}

.form-wizard-form-table {
  max-width: 1024px;
  border: 1px solid #ddd;
  border-radius: 0.25rem;
  margin: 0 auto 2rem;

  h5 {
    margin: 1rem 1rem 0.5rem;
  }

  .header ~ div > div {
    flex: 1 1 auto;
    padding: 0 0.25rem;
  }

  div .input-group {
    height: 100%;
  }

  .table tr [data-action] {
    color: #f25961;
  }

  .table th,
  .table td {
    padding: 0.25rem 0.5rem !important;
  }

  &[data-field] .form-check {
    input[type='checkbox'] {
      @apply static;
    }

    .form-check-sign {
      @apply hidden;
    }
  }
}

.form-wizard-form-array {
  @apply border-solid border-gray-400;
  border-width: 2px 0;

  [data-row]:not(:last-child) {
    @apply border-dotted border-gray-600 mb-2;
    border-width: 0 0 1px;
  }
}

.textarea-chars-remaining {
  float: right;
  padding-top: 0.25rem;
  padding-right: 0.25rem;
}

.form-radio {
  @apply inline-flex items-center py-1 mb-4;
  
  input[type='radio'] {
    @apply hidden;
  }

  input + span {
    @apply relative rounded-full border-2 border-solid border-gray-700 h-6 w-6;
  }

  input:checked + span {
    @apply border-white bg-blue-600 h-4 w-4 mx-1;
    outline: 2px solid black;
    outline-offset: 2px;
  }

  span:last-of-type {
    @apply ml-3;
  }
}

/* FormWizard invoice view: remove excess padding in header */
@media not print {
  .card-invoice.card-invoice .card-header {
    padding-bottom: 0;
  }
}

/* Styles for DIR check in custom html sweetalert */

#__dir-check {
  div {
    @apply flex flex-col justify-center my-3;

    @screen md {
      @apply flex-row items-center;
    }
  }

  label {
    @apply font-bold text-left text-base w-40 pr-3;

    @screen md {
      @apply text-right;
    }
  }

  input {
    @apply m-0;
  }
}
