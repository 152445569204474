.login.login .wrapper.wrapper-login-full {
  @apply justify-center;

  .login-aside.login-aside {
    overflow-x: hidden;
    position: relative;
    max-width: 960px;

    & > img {
      position: absolute;
      width: 40vw;
      max-width: 720px;
      height: auto;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 0;
    }

    & > div {
      position: relative;
      z-index: 1;
    }
  }
}
