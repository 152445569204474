#faqs-page {
  .card-header:not(.collapsed) .span-mode .fa-plus,
  .card-header.collapsed .span-mode .fa-minus {
    display: none
  }
  
  .card-header.collapsed .span-mode .fa-plus,
  .card-header:not(.collapsed) .span-mode .fa-minus {
    display: block
  }
  
  .card-header .span-mode:before {
    content: none !important;
  }
  
  .nav .nav-link.nav-link {
    color: #454545 !important;
  }
  
  .nav .nav-link.nav-link.active {
    color: #FFF !important;
  }
  
  .nav-pills.nav-pills-icons .nav-link {
    padding: 0.75rem
  }
}
