.service-feedback-form .swal-title {
  padding: 0.875rem 0;
}

.service-feedback-form .rating-emojis {
  margin: 1rem;
}

.service-feedback-form .rating-emojis .d-flex {
  margin-top: 0.5rem;
}

.service-feedback-form .rating-emoji {
  width: 18%;
  height: auto;
  padding: 0.5rem;
  border-radius: 50%;
}

.service-feedback-form .rating-emoji.rating-1:hover {
  background-color: rgba(255, 48, 48, 0.25);
}

.service-feedback-form .rating-emoji.rating-1.active {
  background-color: rgba(255, 48, 48, 1);
}

.service-feedback-form .rating-emoji.rating-2:hover {
  background-color: rgba(255, 48, 48, 0.25);
}

.service-feedback-form .rating-emoji.rating-2.active {
  background-color: rgba(255, 48, 48, 0.5);
}

.service-feedback-form .rating-emoji.rating-3:hover {
  background-color: #ccc;
}

.service-feedback-form .rating-emoji.rating-3.active {
  background-color: #777;
}

.service-feedback-form .rating-emoji.rating-4:hover {
  background-color: rgba(64, 180, 48, 0.25);
}

.service-feedback-form .rating-emoji.rating-4.active {
  background-color: rgba(64, 180, 48, 0.5);
}

.service-feedback-form .rating-emoji.rating-5:hover {
  background-color: rgba(64, 180, 48, 0.25);
}

.service-feedback-form .rating-emoji.rating-5.active {
  background-color: rgba(64, 180, 48, 1);
}

.service-feedback-form textarea {
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 400px;
}

.btn-primary.btn-primary:disabled,
.btn-primary.swal-button:disabled {
  background: #ccc !important;
  border-color: #ccc !important;
  color: #555 !important;
  cursor: not-allowed;
}
