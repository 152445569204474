@import 'assets/css/atlantis.min.css';
@import 'assets/css/demo.css';
@import 'assets/css/responsive.css';
@import 'assets/css/form-wizard.css';
@import 'assets/css/login.css';

@import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
@import '../node_modules/react-datepicker/dist/react-datepicker.css';
@import '../node_modules/react-confirm-alert/src/react-confirm-alert.css';

/* @import 'tailwindcss/base'; */
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@layer base {
  body {
    margin: 0;
    background: #f9fbfd !important;
    font-family: 'Poppins', 'NEXA', 'Gill Sans MT', 'Segoe UI', -apple-system,
      BlinkMacSystemFont, 'Segoe UI', 'Roboto', sans-serif;
    color: #575962 !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  .list-checkmark {
    @apply list-none;

    li::before {
      @apply -ml-5 pr-3;
      content: '\2713';
    }
  }
}

:is(li, button, a, img)[data-disabled] {
  pointer-events: none;
  color: #5555;
}

:is(li, button, a, img)[data-hide] {
  display: none;
}

[data-hide]:not([data-hide='false']) {
  display: none !important;
}

[data-disabled]:not([data-disabled='false']) {
  @apply cursor-not-allowed;
}
