/* Page 404 */
.main-panel,
.main-panel > .container {
  /* width: 100vw !important; */
}

.page-not-found {
  width: 100vw;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 9999;
  background-image: url('../images/main-bg.jpg');
  background-size: cover;
  background-position: center;
  image-rendering: pixelated;
}

.main-panel > .container > .page-not-found {
  left: -275px;
}

.page-not-found .wrapper.not-found {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff;
  background: rgba(0, 0, 0, 0.61);
}

.page-not-found .wrapper.not-found h1 {
  font-size: 100px;
  letter-spacing: 0.15em;
  font-weight: 600;
  animation-delay: 0.5s;
}

.page-not-found .wrapper.not-found .desc {
  font-size: 27px;
  text-align: center;
  line-height: 50px;
  animation-delay: 1.5s;
  letter-spacing: 2px;
}

.page-not-found .wrapper.not-found .desc span {
  font-weight: 600;
  font-size: 30px;
}

.page-not-found .wrapper.not-found .btn-back-home {
  border-radius: 50px;
  padding: 13px 25px;
  animation-delay: 2.5s;
}

@media screen and (max-width: 576px) {
  .wrapper.not-found h1 {
    font-size: 65px !important;
 }
  .wrapper.not-found .desc {
    font-size: 18px !important;
 }
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}


@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}


.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}


.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}
