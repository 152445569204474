.wysiwyg-editor {
  &__toolbar {
  }

  &__wrapper {
    height: 300px;
    overflow-y: hidden;
    border: 1px solid #ddd;
  }

  &__editor {
    height: 500px;
    padding-right: 20px;
    padding-left: 20px;
  }
}
